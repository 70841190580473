const usLangReport = {
  'report.col.code': 'Mã HD',
  'report.col.name': 'Món ăn',
  'report.order.breadcrumb.title': 'Danh sách các món bị hủy',
  'report.staff.breadcrumb.title': 'Danh sách nhân viên hay hủy món',
  'report.col.date': 'Ngày',
  'report.col.total.name': 'Số lượng',
  'report.col.total-date.name': 'Tổng số lượng',
  'report.col.name.label': 'Nhân viên',
  'report.col.date.label': 'Thời gian',
  'report.col.time.label': 'Số lần hủy món',
  'report.col.total.label': 'Tổng số lần hủy món',

  'report.revenue.code': 'Code',
  'report.revenue.customer': 'Customer',
  'report.revenue.money': 'money',
  'report.revenue.discount': 'Discount',
  'report.revenue.total': 'Total',
  'report.revenue.voucher': 'Voucher',
  'report.revenue.status': 'Status',
  'report.revenue.time': 'Time',
  'report.reveneu.number': 'No',
  'report.reveneu.title': 'Title',
  'report.reveneu.content': 'Content',
  'report.reveneu.detail-request': 'See more details',
  'report.reveneu.detail-hidden': 'Hide',
  'report.discount.product.voucher': 'Voucher product',

  'report.breadcrumb.export.slips.title': 'Report by Export Ticket',
  'report.breadcrumb.customer.title': 'Report by Customer',
  'report.breadcrumb.product.title': 'Report by Product',
  'report.breadcrumb.staff.title': 'Report by Staff',
  'report.breadcrumb.inventory.title': 'Inventory all',
  'report.breadcrumb.inventory.detail.title': 'Inventory detail',

  'report.breadcrumb.export.slips.parent': '(now)',
  'report.export.slips.label.total': 'Total:',

  'report.export.slips.label.filter-1': 'Select filter 2',
  'report.export.slips.label.filter-2': 'Select filter 2',
  'report.export.slips.label.filter-3': 'Select filter 3',
  'report.export.slips.label.filter-4': 'Select filter 4',

  'report.export.slips.table.thead.stt': 'Ordinal number',
  'report.export.slips.table.thead.receipt': 'Receipt',

  'report.export.slips.table.thead.date.receipt': 'Date receipt',
  'report.export.slips.table.thead.code.product': 'Code product',

  'report.export.slips.table.thead.staff': 'Staff',
  'report.export.slips.table.thead.customer': 'Customer',
  'report.export.slips.table.thead.discount': 'Discount',
  'report.export.slips.table.thead.money': 'Money',
  'report.export.slips.table.thead.total': 'Total money',
  'report.export.slips.table.thead.note': 'Note',
  'report.export.slips.table.tbody.date': 'Export date:',
  'report.export.slips.table.tbody.total': 'ADD BY ROW GROUP',
  'report.inventory.table.tbody.total': 'TOTAL GROUP',

  'report.export.slips.table.thead.code.staff': 'Staff code',
  'report.export.slips.table.thead.name.staff': 'Staff name',
  'report.export.slips.table.thead.sale.money': 'Money sale',
  'report.export.slips.table.thead.cash': 'Cash',
  'report.export.slips.table.thead.debt': 'Debt',

  'report.export.slips.table.thead.name.product.inventory': 'Product name',
  'report.export.slips.table.thead.qc': 'Unit',
  'report.export.slips.table.thead.inventory.start': 'Inventory begin of the period',
  'report.export.slips.table.thead.inventory.end': 'Ending inventory',
  'report.export.slips.table.thead.inventory.import': 'Import',
  'report.export.slips.table.thead.inventory.export': 'Export',
  'report.export.slips.table.thead.inventory.waiting.export': 'Waiting export',
  'report.export.slips.table.thead.inventory.require.import': 'Require import',
  'report.export.slips.table.thead.total.product': 'Total product',

  'report.export.slips.table.thead.date': 'Date sale',
  'report.export.slips.table.thead.category.product': 'Category product',
  'report.export.slips.table.thead.name.product': 'Product name',
  'report.export.slips.table.thead.unit.product': 'Unit',
  'report.export.slips.table.thead.number.product': 'Number',
  'report.export.slips.table.thead.price.product': 'Price',

};

export default usLangReport;
