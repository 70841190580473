const ordersAllTrans = {
  'list-orders-all.helmet': 'All orders',
  'list-orders-all.breadcrumb.title': 'List of orders',
  'list-orders-all.breadcrumb.parent': 'Order management',
  'list-orders-all.col.date': 'Date of application',
  'list-orders-all.col.date.expiry': 'Expiry',

  'list-orders-all.col.code': 'Code orders',
  'list-orders-all.col.customer': 'Client',
  'list-orders-all.col.products': 'Product',
  'list-orders-all.col.products.code': 'Sp',
  'list-orders-all.col.products.barcode': 'Barcode',
  'list-orders-all.col.products.name': 'SP name',
  'list-orders-all.col.products.number': 'Quantity',
  'list-orders-all.col.payment': 'Pay',
  'list-orders-all.col.payment.cash': 'Cash',
  'list-orders-all.col.payment.cod': 'COD',
  'list-orders-all.col.payment.bank': 'Transfer',
  'list-orders-all.modal.detail.title.customer': 'Customer information',
  'list-orders-all.modal.detail.title.order': 'Information line',
  'list-orders-all.modal.detail.title.staff': 'Staff information',
  'list-orders-all.modal.name.label': 'Customer name',
  'list-orders-all.modal.name.staff.label': 'Staff name',
  'list-orders-all.modal.detail.total.order': 'Total price',

  'list-orders-all.modal.phone.label': 'Phone number',
  'list-orders-all.modal.address.label': 'Address',
  'list-orders-all.modal.order-code.label': 'Single code',
  'list-orders-all.modal.order-status.label': 'Payments',
  'list-orders-all.modal.created.label': 'Date of application',
  'list-orders-all.modal.note.label': 'Note of order',
  'list-orders-all.modal.status.label': 'Status order',
  'list-orders-all.modal.items.label': 'List of products',
  'list-orders-all.modal.items.stt.col': 'stt',
  'list-orders-all.modal.items.info.col': 'Product information',
  'list-orders-all.modal.items.name.col': 'SP name',
  'list-orders-all.modal.items.code.col': 'Sp',
  'list-orders-all.modal.items.barcode.col': 'Barcode',
  'list-orders-all.modal.items.unit.col': 'Unit',
  'list-orders-all.modal.items.source.col': 'Origin',
  'list-orders-all.modal.items.desc.col': 'Note',
  'list-orders-all.modal.items.number.col': 'Quantity',
  'list-orders-all.modal.items.number.quantity.col': 'Put',
  'list-orders-all.modal.items.number.return.col': 'Pay',
  'list-orders-all.modal.items.price.col': 'Unit price',
  'list-orders-all.modal.items.discount.col': 'Discount',
  'list-orders-all.modal.items.total.col': 'into money',
  'list-orders-all.modal.discount.label': 'Discount',
  'list-orders-all.modal.total.label': 'total',
  'list-orders-all.title-empty': '(No data)',
  'list-orders-all.note.copy': 'Copy successful customer information',
  'list-orders-all.btn.detail': 'Order details',
  'list-orders-all.btn.confirm': 'Confirm order',
  'list-orders-all.btn.reset': 'return to the previous state',
  'list-orders-all.btn.update.complete': 'Update status complete',

  'list-orders-all.btn.return': 'Returns',
  'list-orders-all.modal.discount_percentage.label': 'Discount percentage (%)',
  'list-orders-all.modal.discount_percentage.placeholder': 'Input discount percentage',
  'list-orders-all.modal.header.detail.title': 'Details of order information',
  'list-orders-all.modal.header.return.title': 'Returns',
  'list-orders-all.modal.header.cancel.title': 'Order cancellation details',
  'list-orders-all.modal.header.update.title': 'Order Coordination',
  'list-orders-all.note.create-order-return.success': 'Successful return!',
  'list-orders-all.note.create-order-return.failure': 'Successful return!Please try again later.',
  'list-orders-all.guest': 'Visitors',
  'list-orders-all.col.info.customer': 'Recipient information',
  'list-orders-all.col.status.order': 'Status',
  'list-orders-all.col.single.maker': 'Single maker',
  'list-orders-all.col.date.wholesale': 'Order Creation Date',
  'order.preview': 'Order preview',
  'order.modal.update.confirm.title': 'Confirm order status update',
  'order.modal.update.confirm.description': 'Are you sure you want to update the order status?',
  'order.modal.delete.confirm.title': 'Confirm cancel order',
  'order.modal.delete.confirm.description': 'Are you sure you want to cancel order?',
  'order-modal.update-order-status.failure': 'Update failure',
  'order-modal.update-order-status.success': 'Update success',
  'order-modal.cancel-order.success': 'Cancel order success',
  'order-modal.cancel-order.failure': 'Cancel order failure',
  'order-modal.confirm-order.success': 'Accept order success',
  'order-modal.confirm-order.error': 'Accept order error',
};

const vatTrans = {
  'order.vat.create': 'Add VAT invoice',
  'order.vat.list': 'VAT invoice list',
  'order.vat.edit': 'Update VAT invoice',
  'order.vat.delete': 'Delete VAT invoice',
  'order.vat.manage': 'VAT invoice management',
  'order.vat.create.confirm': 'Confirmation of VAT invoice creation',
  'order.vat.create.confirmSure': 'You definitely create VAT invoice?',
  'order.vat.edit.confirm': 'Confirmation of VAT invoice update',
  'order.vat.edit.confirmSure': 'You definitely update VAT invoice?',
  'order.vat.create.success': 'Create successful VAT invoice',
  'order.vat.update.success': 'Successful VAT invoice update',
  'order.vat.delete.confirm': 'Confirmation of VAT invoice deletion',
  'order.vat.delete.confirmSure': 'You definitely delete VAT invoice?',
  'order.vat.delete.success': 'Successful VAT invoice deletion',

  'order.vat.title': 'VAT invoice issue',
  'order.vat.name': 'Customer name',
  'order.vat.name.required': 'Please enter the customer name',
  'order.vat.email': 'email',
  'order.vat.email.invalid': 'Email invalidate',
  'order.vat.phone': 'Phone number',
  'order.vat.phone.invalid': 'Phone number improperly formatted',
  'order.vat.taxcode': 'Tax code',
  'order.vat.taxcode.required': 'Please enter the tax code',
  'order.vat.percent': 'Percentage of surcharges',
  'order.vat.percent.money': 'Surcharge',
  'order.vat.address': 'Address',
  'order.vat.address.required': 'Please enter your address',
  'order.vat.order': 'Desk information',
  'order.vat.btn-1': 'Invoice',
  'order.vat.noti-update-vat-success': 'Successful invoice',
};

const usLangOrder = {
  ...vatTrans,
  ...ordersAllTrans,
};

export default usLangOrder;
