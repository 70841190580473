const vnLangInventory = {
  'inventory.helmet': 'Danh sách tồn kho',
  'inventory.breadcrumb.title': 'Danh sách tồn kho',
  'inventory.breadcrumb.parent': 'Bán hàng',
  'inventory.modal.create.title': 'Thêm tồn kho',
  'inventory.modal.create.confirm.title': 'Xác nhận thêm tồn kho mới',
  'inventory.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm tồn kho này?',
  'inventory.modal.detail.title': 'Thông tin chi tiết tồn kho',
  'inventory.modal.update.title': 'Thông tin sản phẩm tồn kho',
  'inventory.modal.update.confirm.title': 'Xác nhận cập nhật thông tin tồn kho',
  'inventory.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin tồn kho này?',
  'inventory.modal.delete.title': 'Xác nhận xoá tồn kho',
  'inventory.modal.delete.description': 'Bạn chắc chắn muốn xoá tồn kho này?',
  'inventory.modal.reset-password.title': 'Đặt lại mật khẩu',
  'inventory.noti.create.success': 'Thêm tồn kho thành công!',
  'inventory.noti.create.failure': 'Thêm tồn kho không thành công! Vui lòng thử lại sau.',
  'inventory.noti.update.success': 'Cập nhật tồn kho thành công!',
  'inventory.noti.update.failure': 'Cập nhật tồn kho không thành công! Vui lòng thử lại sau.',
  'inventory.noti.delete.success': 'Xoá tồn kho thành công!',
  'inventory.noti.delete.failure': 'Xoá tồn kho không thành công! Vui lòng thử lại sau.',
  'inventory.noti.reset-password.success': 'Đặt lại mật khẩu tồn kho thành công!',
  'inventory.noti.reset-password.failure': 'Đặt lại mật khẩu tồn kho không thành công! Vui lòng thử lại sau.',
  'inventory.btn-create': 'Thêm',
  'inventory.error-min-length': 'Tối thiểu $x ký tự!',
  'inventory.error-max-length': 'Tối đa $x ký tự!',
  'inventory.code.col': 'Mã tồn kho',
  'inventory.code.label': 'Mã tồn kho',
  'inventory.code.placeholder': 'Nhập mã tồn kho',
  'inventory.barcode.col': 'Mã vạch',
  'inventory.barcode.label': 'Mã vạch',
  'inventory.barcode.placeholder': 'Nhập mã vạch',
  'inventory.categories.col': 'Dòng tồn kho',
  'inventory.categories.label': 'Dòng tồn kho',
  'inventory.categories.placeholder': 'Chọn dòng tồn kho',
  'inventory.categories.error.required': 'Vui lòng chọn dòng tồn kho!',
  'inventory.name.col': 'Tên tồn kho',
  'inventory.name.label': 'Tên tồn kho',
  'inventory.name.placeholder': 'Nhập tên tồn kho',
  'inventory.name.error.required': 'Vui lòng nhập tên tồn kho!',
  'inventory.desc.col': 'Mô tả',
  'inventory.desc.label': 'Mô tả tồn kho',
  'inventory.desc.placeholder': 'Nhập mô tả tồn kho',
  'inventory.unit.col': 'Đơn vị',
  'inventory.unit.label': 'Đơn vị tính cơ bản',
  'inventory.unit.placeholder': 'Nhập đơn vị tính cơ bản',
  'inventory.unit.error-required': 'Vui lòng nhập đơn vị tính cơ bản!',
  'inventory.warehouse.col': 'Tồn kho',
  'inventory.price.label': 'Giá bán (vnđ)',
  'inventory.price.placeholder': 'Nhập giá bán',
  'inventory.price.error-required': 'Vui lòng nhập giá bán!',
  'inventory.price.invalid': 'Nhập giá lớn hơn 0!',
  'inventory.units.name.label': 'Tên đơn vị',
  'inventory.units.title.label': 'Tiêu đề đơn vị',
  'inventory.units.name.placeholder': 'Nhập tên đơn vị',
  'inventory.units.name.error-required': 'Vui lòng nhập tên đơn vị!',
  'inventory.units.title.placeholder': 'Nhập tiêu đề đơn vị',
  'inventory.units.title.error-required': 'Vui lòng nhập tiêu đề đơn vị!',
  'inventory.units.number.label': 'Giá trị quy đổi',
  'inventory.units.number.placeholder': 'Nhập giá trị quy đổi',
  'inventory.units.number.error-required': 'Vui lòng nhập giá trị quy đổi!',
  'inventory.units.number.error-invalid': 'Giá trị quy đổi phải lớn hơn 0!',
  'inventory.units.price.label': 'Giá bán (vnđ)',
  'inventory.units.price.placeholder': 'Nhập giá bán',
  'inventory.units.price.error-required': 'Vui lòng nhập giá bán!',
  'inventory.units.code.label': 'Mã tồn kho',
  'inventory.units.code.placeholder': 'Nhập mã tồn kho',
  'inventory.units.barcode.label': 'Mã vạch',
  'inventory.units.barcode.placeholder': 'Nhập mã vạch',
  'inventory.image.col': 'Hình ảnh',
  'inventory.image.label': 'Tải lên',
  'inventory.image.label-2': 'Hình ảnh tồn kho',
  'inventory.image.error-required': 'Vui lòng tải lên ảnh tồn kho!',
  'inventory.btn-add-units-items': 'Thêm đơn vị tính',
  'inventory.serial.label': 'Sử dụng số serial/imei',
  'inventory.quantity.label': 'Áp dụng quản lý số lượng kho',
  'inventory.expiry.label': 'Áp dụng ngày hết hạn',
  'inventory.cost.label': 'Giá vốn',
  'inventory.discount.label': 'Giá gốc',
  'inventory.cost.placeholder': 'Nhập giá vốn',
  'inventory.trending.label': 'tồn kho nổi bật',
  'inventory.discount.placeholder': 'Nhập giá gốc',

  'inventory.kitchen.label': 'Bếp',
  'inventory.kitchen.placeholder': 'Chọn bếp',
  'inventory.kitchen.error.required': 'Vui lòng chọn bếp!',
  'inventory.wholesale.price.label': 'Giá bán sỉ',
  'inventory.wholesale.price.placeholder': 'Nhập giá bán sỉ',
  'inventory.wholesale.quantity.label': 'Số lượng áp dụng giá sỉ',
  'inventory.wholesale.quantity.placeholder': 'Nhập số lượng áp dụng giá sỉ',

  'inventory.wholesale.price.error-required': 'Vui lòng nhập giá bán sỉ!',
  'inventory.wholesale.price.invalid': 'Nhập giá sỉ lớn hơn 0!',
  'inventory.wholesale.quantity.error-required': 'Vui lòng nhập số lượng áp dụng giá bán sỉ!',
  'inventory.wholesale.quantity.invalid': 'Nhập số lượng áp dụng giá sỉ lớn hơn 0!',
  'inventory.warehouse.label': 'Số lượng sản phẩm trong kho',
  'inventory.warehouse.note': 'Ghi chú xuất kho: ',
};

export default vnLangInventory;
