const usLangProduct = {
  'product.helmet': 'List of products',
  'product.breadcrumb.title': 'List of products',
  'product.breadcrumb.parent': 'Sell',
  'product.modal.create.title': 'Add product',
  'product.modal.create.confirm.title': 'Confirm new products',
  'product.modal.create.confirm.description': 'You definitely want to add this product?',
  'product.modal.detail.title': 'the detail information of product',
  'product.modal.update.title': 'Update product information',
  'product.modal.update.confirm.title': 'Confirmation of product information updates',
  'product.modal.update.confirm.description': 'You definitely want to update this product information?',
  'product.modal.delete.title': 'Product deletion confirmation',
  'product.modal.delete.description': 'You definitely want to delete this product?',
  'product.modal.reset-password.title': 'Reset Password',
  'product.noti.create.success': 'Add successful products!',
  'product.noti.create.failure': 'Add unsuccessful products!Please try again later.',
  'product.noti.update.success': 'Update the product successfully!',
  'product.noti.update.failure': 'Update the product unsuccessful!Please try again later.',
  'product.noti.delete.success': 'Delete products successfully!',
  'product.noti.delete.failure': 'Delete products failed!Please try again later.',
  'product.noti.reset-password.success': 'Retite a successful product password!',
  'product.noti.reset-password.failure': 'Reset the product password failed!Please try again later.',
  'product.btn-create': 'Create',
  'product.error-min-length': 'Minimum $x character!',
  'product.error-max-length': 'Maximum $x character!',
  'product.code.col': 'Product code',
  'product.code.label': 'Product code',
  'product.code.placeholder': 'Enter the product code',
  'product.barcode.col': 'Barcode',
  'product.barcode.label': 'Barcode',
  'product.barcode.placeholder': 'Enter the barcode',
  'product.categories.col': 'Product line',
  'product.categories.label': 'Product line',
  'product.categories.placeholder': 'Choose a product line',
  'product.categories.error.required': 'Please choose the product line!',
  'product.name.col': "Product's name",
  'product.name.label': "Product's name",
  'product.name.placeholder': 'Enter the name of the product',
  'product.name.error.required': 'Please enter the product name!',
  'product.desc.col': 'Describe',
  'product.desc.label': 'Product Description',
  'product.desc.placeholder': 'Enter the product description',
  'product.unit.col': 'Unit',
  'product.unit.label': 'Basic unit',
  'product.unit.placeholder': 'Enter the basic unit',
  'product.unit.error-required': 'Please enter the basic unit!',
  'product.cost.col': 'Cost price',
  'product.origin_price.col': 'Original price',
  'product.source.col': 'Origin',
  'product.expiry.col': 'Apply expiration date',
  'product.typical.col': 'Featured product',
  'product.price.col': 'Retail price',
  'product.wholesale_price.col': 'Wholesale price',
  'product.wholesale_quantity.col': 'Wholesale price applicable quantity',
  'product.price.label': 'Price (VND)',
  'product.price.placeholder': 'Enter the selling price',
  'product.price.error-required': 'Please enter the selling price!',
  'product.price.invalid': 'Enter the price greater than 0!',
  'product.units.name.label': 'Unit name',
  'product.units.title.label': 'Unit title',
  'product.units.name.placeholder': 'Enter the name of the unit',
  'product.units.name.error-required': 'Please enter the name of the unit!',
  'product.units.title.placeholder': 'Enter the title of the unit',
  'product.units.title.error-required': 'Please enter the title of the unit!',
  'product.units.number.label': 'Exchange value',
  'product.units.number.placeholder': 'Enter the conversion value',
  'product.units.number.error-required': 'Please enter the conversion value!',
  'product.units.number.error-invalid': 'The conversion value must be greater than 0!',
  'product.units.price.label': 'Price (VND)',
  'product.units.price.placeholder': 'Enter the selling price',
  'product.units.price.error-required': 'Please enter the selling price!',
  'product.units.code.label': 'Product code',
  'product.units.code.placeholder': 'Enter the product code',
  'product.units.barcode.label': 'Barcode',
  'product.units.barcode.placeholder': 'Enter the barcode',
  'product.image.col': 'Image',
  'product.image.label': 'Upload',
  'product.image.label-2': 'Product image',
  'product.image.error-required': 'Please upload the product photo!',
  'product.btn-add-units-items': 'Add unit',
  'product.serial.label': 'Use serial/imei',
  'product.quantity.label': 'Apply the number of warehouses',
  'product.expiry.label': 'Apply expiry date',
  'product.cost.label': 'Cost',
  'product.discount.label': 'Origin price',
  'product.cost.placeholder': 'Input capital cost',
  'product.trending.label': 'Product trending',
  'product.discount.placeholder': 'Input origin price',
  'product.wholesale.price.label': 'Wholesale price',
  'product.wholesale.discount.label': 'Discount (%)',
  'product.wholesale.price.placeholder': 'Enter wholesale price',
  'product.wholesale.quantity.label': 'Wholesale applicable quantity',
  'product.wholesale.quantity.placeholder': 'Enter wholesale applicable quantity',
  'product.wholesale.price.error-required': 'Please enter wholesale price!',
  'product.wholesale.price.invalid': 'Enter wholesale price greater than 0!',
  'product.wholesale.quantity.error-required': 'Please enter the quantity to which the wholesale price applies!',
  'product.wholesale.quantity.invalid': 'Enter a wholesale applicable quantity greater than 0!',
  'product.import.excel': 'Import excel',
  'product.export.excel': 'Export excel',
  'product.price.affect.discount.label': 'Affected by the promotion',
  'product.update.price.affect.origin.price.label': 'Update current price by selling price',
};

export default usLangProduct;
