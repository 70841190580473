const vnLangSale = {
  'sale.helmet': 'Bán hàng',
  'sale.code': 'Mã',
  'sale.gift': 'Quà tặng',
  'sale.cash': 'Tiền mặt',
  'sale.number': 'Trị giá',
  'sale.percent': 'Quy đổi',
  'sale.maximum': 'Giảm tối đa',
  'sale.description': 'Mô tả',
  'sale.duplicate': 'Cho phép dùng chung voucher',
  'sale.name': 'Tên',
  'sale.btn-unselect-discount': 'Bỏ chọn mã giảm giá',
  'sale.discount-placeholder': 'Chọn mã giảm giá',
  'sale.discount-empty-title': 'Hiện chưa có mã giảm giá/quà tặng nào.',
  'sale.create-order-success': 'Tạo đơn hàng thành công!',
  'sale.update-order-success': 'Cập nhật đơn hàng thành công!',
  'sale.update-order-error': 'Cập nhật đơn hàng thất bại!',
  'sale.create-order-failure': 'Tạo đơn hàng không thành công! Vui lòng thử lại sau.',
  'sale.create-order-loading': 'Đang tiến hành tạo đơn hàng...',
  'sale.header.search-input.placeholder-1': 'Tìm hàng hoá',
  'sale.header.search-input.placeholder-2': 'Tìm đơn thuốc mẫu',
  'sale.header.type.search-sale': 'Bán hàng',
  'sale.header.type.search-gift': 'Quà tặng',
  'sale.header.order-tab-name': 'Hoá đơn',
  'sale.header.btn-delete-order-tab': 'Xoá',
  'sale.header.btn-add-order-tab': 'Thêm',
  'sale.header.link.admin': 'Trang quản lý',
  'sale.header.link.kitchen': 'Trang bếp',
  'sale.header.link.logout': 'Đăng xuất',
  'sale.header.btn-add-product': 'Thêm mới hàng hoá',
  'sale.footer.tab.sell-quickly': 'Bán nhanh',
  'sale.footer.tab.sell-casual': 'Tồn kho',
  'sale.footer.btn-tutorial': 'Hướng dẫn sử dụng',
  'sale.footer.btn-letter': 'Góp ý',
  'sale.item.more-action.note': 'Ghi chú',
  'sale.item.more-action.detail': 'Chi tiết sản phẩm',
  'sale.item.note.placeholder': 'Ghi chú...',
  'sale.container.footer.note-placeholder': '✎ Ghi chú đơn hàng',
  'sale.container.footer.total-price-tmp': 'Tổng tiền hàng',
  'sale.sell-casual-tab.btn-filter': 'Lọc sản phẩm',
  'sale.sell-casual-tab.btn-view-mode-list': 'Chế độ danh sách',
  'sale.sell-casual-tab.btn-view-mode-thumbnail': 'Chế độ hình ảnh',
  'sale.sell-casual-tab.btn-payment': 'Thanh toán',
  'sale.sell-casual-tab.btn-print': 'In hóa đơn',

  'sale.sell-casual-tab.current-point': 'Số điểm hiện tại',
  'sale.sell-casual-tab.use-point': 'Sử dụng điểm tích lũy',
  'sale.sell-casual-tab.use-point-placeholder': 'Nhập số điểm bạn muốn sử dụng',

  'sale.sell-casual-tab.title-1': 'Nhân viên bán hàng',
  'sale.sell-casual-tab.title-2': 'Trạng thái đơn hàng',
  'sale.sell-casual-tab.title-3': 'Chiết khấu đơn hàng',
  'sale.sell-casual-tab.title-4': 'Tổng tiền hàng',
  'sale.sell-casual-tab.title-5': 'Khách cần trả',
  'sale.sell-casual-tab.title-6': 'Khách thanh toán',
  'sale.sell-casual-tab.title-7': 'Tiền thừa trả khách',

  'sale.sell-casual-tab.payment-type.cash': 'Tiền mặt',
  'sale.sell-casual-tab.payment-type.bank': 'Chuyển khoản',
  'sale.sell-casual-tab.payment-type.COD': 'COD',
  'sale.sell-casual-tab.payment-type.card': 'Thẻ',
  'sale.sell-casual-tab.add-account-bank-title': 'Thêm tài khoản ngân hàng',
  'sale.loading-customer-title': 'Đang tìm kiếm...',
  'sale.search-customer-placeholder': 'Tìm khách hàng',
  'sale.search-voucher-placeholder': 'Nhập mã voucher',
  'sale.btn-unselect-user': 'Bỏ chọn khách hàng',
  'sale.btn-add-user': 'Thêm khách hàng mới',
  'sale.select-discount': 'Chọn mã giảm giá',
  'sale.modal.customer.create.title': 'Thêm khách hàng mới',
  'sale.modal.customer.detail.title': 'Thông tin khách hàng',
  'sale.modal.customer.update.title': 'Cập nhật thông tin khách hàng',
  'sale.modal.customer.error-length-max': 'Tối đa $x ký tự!',
  'sale.modal.customer.name.col': 'Tên khách hàng',
  'sale.modal.customer.name.placeholder': 'Nguyễn Văn A',
  'sale.modal.customer.name.error-required': 'Vui lòng nhập tên khách hàng!',
  'sale.modal.customer.phone.col': 'Điện thoại',
  'sale.modal.customer.phone.placeholder': '08xxxxxxxx',
  'sale.modal.customer.phone.error-required': 'Vui lòng nhập số điện thoại!',
  'sale.modal.customer.phone.error-invalid': 'Số điện thoại không hợp lệ!',
  'sale.modal.customer.phone.is-exist': 'Số điện thoại đã được đăng ký!',
  'sale.modal.customer.email.col': 'Email',
  'sale.modal.customer.email.placeholder': 'email@example.com',
  'sale.modal.customer.email.error-invalid': 'Email không hợp lệ!',
  'sale.modal.customer.address.col': 'Địa chỉ',
  'sale.modal.customer.address.placeholder': 'VD: Hưng Lợi, Cái Răng, TPCT',
  'sale.modal.customer.birthday.col': 'Ngày sinh',
  'sale.modal.note.create-customer.success': 'Thêm khách hàng mới thành công!',
  'sale.modal.note.create-customer.failure': 'Thêm khách hàng mới không thành công! Vui lòng thử lại sau.',
  'sale.modal.note.create-customer.phone.duplicate': 'Số điện thoại đã được đăng ký.',
  'sale.modal.note.create-customer.email.duplicate': 'Email đã được đăng ký.',
  'sale.modal.note.update-customer.success': 'Cập nhật thông tin khách hàng thành công!',
  'sale.modal.note.update-customer.failure': 'Cập nhật thông tin khách hàng không thành công! Vui lòng thử lại sau.',
  'sale.modal.btn-create': 'Thêm',
  'sale.modal.btn-update': 'Cập nhật',
  'sale.title-add-customer': 'Thêm khách hàng mới',
  'sale.modal.note.confirm.create-customer.title': 'Xác nhận thêm khách hàng',
  'sale.modal.note.confirm.create-customer.description': 'Bạn chắc chắn muốn thêm khách hàng <strong>$x</strong> này?',
  'sale.modal.note.confirm.update-customer.title': 'Xác nhận cập nhật thông tin khách hàng',
  'sale.modal.note.confirm.update-customer.description': 'Bạn chắc chắn muốn cập nhật thông tin khách hàng <strong>$x</strong> này?',
  'sale.modal.failure.title': 'Có lỗi xảy ra!',
  'sale.modal.failure.description': 'Vui lòng quay lại sau, hoặc bấm vào nút <strong>Làm mới</strong> để thử lại.',
  'sale.modal.failure.btn': 'Làm mới',
  'sale.btn.bill': 'Bán thuốc theo đơn',
  'sale.modal.bill.title': 'Thông tin đơn thuốc',
  'sale.modal.billCode.label': 'Mã đơn thuốc',
  'sale.modal.billCode.error-required': 'Vui lòng nhập mã đơn thuốc!',
  'sale.modal.doctor.label': 'Bác sĩ kê đơn',
  'sale.modal.hospital.label': 'Cơ sở khám bệnh',
  'sale.modal.name.label': 'Tên bệnh nhân',
  'sale.modal.age.label': 'Tuổi',
  'sale.modal.gender.label': 'Giới tính',
  'sale.modal.gender.male.label': 'Nam',
  'sale.modal.gender.female.label': 'Nữ',
  'sale.modal.weight.label': 'Cân nặng',
  'sale.modal.id_card.label': 'CCCD',
  'sale.modal.id_bhyt.label': 'Thẻ BHYT',
  'sale.modal.address.label': 'Địa chỉ',
  'sale.modal.phone.label': 'Điện thoại liên hệ',
  'sale.modal.note.label': 'Chuẩn đoán',
  'sale.modal.bill.btn.back': 'Bỏ qua',
  'sale.modal.bill.btn.save': 'Xong',
  'sale.failure.title.missing-customer': 'Vui lòng chọn khách hàng cho đơn hàng!',
  'sale.failure.description.missing-customer': 'Đối với khách vãng lai thì chọn <strong>Khách</strong>.',
  'sale.failure.description.missing-bill-code': 'Vui lòng nhập <strong>Mã đơn thuốc</strong> với đơn <strong>Bán thuốc theo đơn</strong>.',
  'sale.dropdown.bill-code': 'Mã đơn thuốc',
  'sale.dropdown.order-code': 'Mã đơn hàng',
  'sale.dropdown.username-name': 'Khách hàng',
  'sale.dropdown.username-phone': 'Số điện thoại',
  'sale.empty-bill-info': 'Hiện chưa có kết quả nào cho tìm kiếm của bạn.',
  'sale.staff.btn-remove': 'Bỏ chọn',
  'sale.staff-empty-title': 'Hiện không có nhân viên nào cùng ca với bạn.',
  'sale.staff-loading-title': 'Đang tải danh sách nhân viên trong ca...',
  'sale.dropdown.item.name': 'Tên',
  'sale.dropdown.item.phone': 'SĐT',
  'sale.dropdown.item.email': 'Email',
  'sale.cart-item-total': 'Thành tiền',
  'sale.modal.detail-product': 'Thông tin chi tiết sản phẩm',
  'sale.modal.detail-product.code.label': 'Mã sản phẩm',
  'sale.modal.detail-product.barcode.label': 'Mã vạch',
  'sale.modal.detail-product.name.label': 'Tên sản phẩm',
  'sale.modal.detail-product.image.label': 'Hình ảnh',
  'sale.modal.detail-product.source.label': 'Nguồn gốc / Xuất xứ',
  'sale.modal.detail-product.unit.label': 'Đơn vị tính',
  'sale.modal.detail-product.desc.label': 'Mô tả sản phẩm',
  'sale.modal.detail-product.price.label': 'Giá bán',
  'sale.modal.note-product': 'Thông tin ghi chú sản phẩm',
  'sale.screen-filter-product.title': 'Lọc sản phẩm',
  'sale.screen-filter-product.sub-title-category': 'Danh mục sản phẩm',
  'sale.screen-filter-product.all': 'Tất cả',
  'sale.screen-filter-product.btn-clear': 'Xoá chọn',
  'sale.screen-filter-product.btn-back': 'Quay lại',
  'sale.screen-filter-product.btn-accept': 'Lọc',
  'sale.inventory': 'Số lượng trong kho',
  'sale.inventory-empty': 'Hết hàng',
  'sale.quantity': 'Số lượng',
  'sale.modal.work-places.title': 'Chọn địa điểm lên đơn',
  'sale.modal.error-msg-work-place-require': 'Vui lòng chọn địa điểm lên đơn!',
  'sale.modal.work-places.btn-submit': 'Lên đơn',
  'sale.barcode-scanner': 'Quét mã vạch',
  'sale.render-qr-code.title': 'Thông tin chuyển khoản',
  'sale.my-voucher': 'Voucher của tôi',
  'sale.your-voucher-customer-choose': 'Voucher khách chọn',

  'sale.barcode-scanner-request': 'Quét Bar code của khách hàng để áp dụng voucher',
  'sale.minimum-order': 'Đơn hàng tối thiểu',
  'sale.reduce': 'Giảm',
  'sale.out-date': 'Hết hạn',
  'sale.applying': 'Đang áp dụng',
  'sale.gift.label': 'Quà tặng',
  //
  'sale.fnb.tab-table': 'Phòng bàn',
  'sale.fnb.tab-menu': 'Thực đơn',
  'sale.fnb.tab-order': 'Hoá đơn',
  'sale.fnb.all': 'Tất cả',
  'sale.order.confirm-leave': 'Thông tin đơn hàng chưa được lưu, chắc chắn thoát?',
  'sale.fnb.done': 'Hoàn thành',
  'sale.fnb.doing': 'Đang làm',
  'sale.fnb.used': 'Sử dụng',
  'sale.fnb.empty': 'Còn trống',
  'sale.fnb.take-away': 'Mang về',
  'sale.fnb.modal-note-table.title': 'Ghi chú phòng / bàn',
  'sale.fnb.modal-note-table.title-2': 'Ghi chú món ăn',
  'sale.fnb.modal-note-table.title-3': '✎ Ghi chú / Món thêm',
  'sale.fnb.modal-note-table.btn.done': 'Xong',
  'sale.fnb.modal-note-table.btn.cancel': 'Bỏ qua',
  'sale.fnb.open-menu-when-select-table': 'Mở thực đơn khi chọn bàn',
  'sale.fnb.order-take-away-name': 'Mang về',
  'sale.fnb.note-section.description': '✎ Nhập ghi chú',
  'sale.fnb.order-item-note': 'Nhập Ghi chú / Món thêm',
  'sale.fnb.notify-content': '<i class="simple-icon-bell"></i> Bạn vừa cập nhật đơn hàng. Bấm nút <span>Thông báo</span> để gửi thông tin chế biến đến bar bếp.',
  'sale.fnb.notify-content-2': '<i class="simple-icon-bell"></i> Đơn hàng chờ xử lý.',
  'sale.fnb.btn-payment': 'Thanh toán',
  'sale.fnb.btn-notify': 'Thông báo',
  'sale.fnb.notify-success': 'Bếp đã nhận được thông báo',
  'sale.fnb.form-payment.btn.note': 'Ghi chú',
  'sale.fnb.form-payment.btn.vat': 'Thêm VAT',
  'sale.fnb.form-payment.btn.qr-code': 'QR Code',
  'sale.fnb.form-payment.btn.decoupling': 'Tách ghép',
  'sale.fnb.form-payment.btn.destroy': 'Hủy đơn',
  'sale.fnb.form-payment.btn.cancel-dish': 'Hủy món',
  'sale.fnb.form-payment.btn.total': 'Tổng tiền',
  'sale.fnb.order-take-away': 'Hoá đơn mang về',
  'sale.fnb.order-table': 'Hoá đơn',
  'sale.fnb.order-created': 'Đơn hàng mới',
  'sale.fnb.order-updated': 'Đơn hàng được cập nhật',
  'sale.fnb.modal-decoupling.title-1': 'Ghép đơn',
  'sale.fnb.modal-decoupling.title-2': 'Tách đơn',
  'sale.fnb.modal-decoupling.title-3': 'Ghép đến',
  'sale.fnb.modal-decoupling.title-4': 'Chọn phòng / bàn',
  'sale.fnb.modal-decoupling.title-5': 'Bàn chưa có món nào',
  'sale.fnb.modal-decoupling.title-6': 'Khách hàng',
  'sale.fnb.modal-decoupling.title-7': 'Số lượng hàng',
  'sale.fnb.modal-decoupling.title-8': 'Tổng tiền',
  'sale.fnb.modal-decoupling.title-9': 'Khách lẻ',
  'sale.fnb.modal-decoupling.title-10': 'Tạo đơn mới',
  'sale.fnb.modal-decoupling.title-11': 'SL trên đơn gốc',
  'sale.fnb.modal-decoupling.title-12': 'SL tách',
  'sale.fnb.modal-decoupling.btn-1': 'Thực hiện',
  'sale.fnb.modal-decoupling.btn-2': 'Bỏ qua',
  'sale.fnb.modal-decoupling.noti-grafted': 'Ghép đơn thành công!',
  'sale.fnb.modal-decoupling.noti-detached': 'Tách đơn thành công!',
  'sale.fnb.noti-order-success': 'Tạo hoá đơn và thanh toán thành công!',
  'sale.fnb.noti-order-failure': 'Tạo hoá đơn và thanh toán không thành công! Vui lòng thử lại sau.',

  'sale.fnb.modal-vat.title': 'Xuất hóa đơn VAT',
  'sale.fnb.modal-vat.name': 'Tên khách hàng',
  'sale.fnb.modal-vat.name.required': 'Vui lòng nhập tên khách hàng',
  'sale.fnb.modal-vat.email': 'Email',
  'sale.fnb.modal-vat.email.invalid': 'Email không đúng định dạng',
  'sale.fnb.modal-vat.phone': 'Số điện thoại',
  'sale.fnb.modal-vat.phone.invalid': 'Số điện thoại không đúng định dạng',
  'sale.fnb.modal-vat.taxcode': 'Mã số thuế',
  'sale.fnb.modal-vat.taxcode.required': 'Vui lòng nhập mã số thuế',
  'sale.fnb.modal-vat.percent': 'Phần trăm phụ thu',
  'sale.fnb.modal-vat.address': 'Địa chỉ',
  'sale.fnb.modal-vat.address.required': 'Vui lòng nhập địa chỉ',
  'sale.fnb.modal-vat.btn-1': 'Xuất hóa đơn',
  'sale.fnb-modal-cancel-order.title': 'Hủy bỏ đơn hàng',
  'sale.fnb-modal-cancel-order.content': 'Lưu ý: Đơn hàng này sẽ bị hủy vĩnh viễn và không thể hoàn tác. Bạn có chắc chắn muốn xóa đơn hàng này không?',
  'sale.fnb-modal-cancel-order.confirm': 'Xác nhận',
  'sale.fnb-modal-cancel-order.cancel': 'Hủy',
  'sale.fnb.noti-update-vat-success': 'Xuất hóa đơn thành công',

  'sale.order.move.kitchen': 'Món ăn đã được gởi xuống bếp!',
  'sale.order.confirm.cancel.move-kitchen': 'Xóa món ăn',
  'sale.order.cancel.move-kitchen-not-done': '<p>Món ăn hiện tại không hủy được!</p><p>Nếu khách vẩn muốn hủy món bạn vui lòng liên hệ với <strong>Thu ngân</strong> hoặc <strong>Trưởng ca</strong></p>',
  'sale.order.cancel.move-kitchen-done': '<p>Đơn đã gởi xuống bếp!</p><p>Bạn có chắn chắn gởi yêu cầu hủy món xuống bếp?</p>',
  'sale.order.cancel-dish': 'Gửi yêu cầu',
  'sale.order.confirm-cancel-dish': 'Yêu cầu xóa món ăn',
  'sale.order.confirm-sure-cancel-dish': 'Bạn chắc chắn muốn xóa món ăn?',
  'sale.order.delete.move.kitchen': 'Không xóa được. Sản phẩm đã được chuyển xuống bếp',
  'sale.order.update.less.than.kitchen': 'Cập nhật không thành công. Số lượng cập nhật phải lớn hơn số lượng đã chuyển xuống bếp',
  'sale.order.col.kitchen.id': 'Mã bếp',
  'sale.order.col.kitchen.time': 'Thời gian',
  'sale.order.col.kitchen.status': 'Trạng thái',
  'sale.order.request.move.kitchen': 'Yêu cầu đã được gởi!',
  'sale.order.failure.move.kitchen': 'Gửi yêu cầu không thành công!',
  'sale.fnb.modal-payment-table.btn.done': 'Thanh toán',
  'sale.fnb.modal-send-require-payment-table.btn.done': 'Gửi yêu cầu thanh toán',
  'sale.fnb.create.request.payment.success': 'Gửi yêu cầu thanh toán thành công',
  'sale.fnb.create.request.payment.failure': 'Gửi yêu cầu thanh toán thất bại',
  'sale.fnb.notify.request-to-order': 'Bàn yêu cầu gọi món',
  'sale.fnb.notify.request-payment-to-order': 'Bàn yêu cầu thanh toán',

  'sale.order.step.confirm': 'Xác nhận',
  'sale.order.step.point-stack': 'Tích điểm',
  'sale.order.step.discount': 'Ưu đãi',
  'sale.order.step.payment': 'Thanh toán',
  'sale.order.detail.table': 'Bàn',
  'sale.order.detail.group': 'Khu vực',
  'sale.order.detail.number-bill': 'Số phiếu',
  'sale.order.detail.date': 'Ngày',
  'sale.order.detail.time': 'Thời gian',
  'sale.order.detail.staff': 'NV',
  'sale.order.detail.name': 'Tên',
  'sale.order.detail.quantity': 'SL',
  'sale.order.detail.price': 'Đơn giá',
  'sale.order.detail.total-price': 'Thành tiền',
  'sale.order.detail.total': 'Tổng cộng',
  'sale.order.detail.service-reviews': 'Đánh giá dịch vụ',
  'sale.order.detail.service-comment': 'Nhận xét',
  'sale.fnb.order.status-draft': 'Đang chờ duyệt',
  'sale.fnb.order.status-paid': 'Đã thanh toán',
  'sale.fnb.order.status-canceled': 'Đã hủy',
  'sale.fnb.order.status-printed': 'Đã in',
  'sale.fnb.order.status-processed': 'Đã xử lý',
  'sale.fnb.order.status-completed': 'Đã hoàn thành',
  'sale.fnb.order.status-approved': 'Đã duyệt',
  'sale.fnb.order.status-approved-not-number-product': 'Chờ xuất kho',

  'sale.order.confirm-order-change': 'Bạn vừa cập nhật đơn hàng. Vui lòng bấm nút Thông báo để gửi thông tin!',
  'sale.order.confirm-order-change-2': 'Đơn hàng chờ xử lý!',
  'sale.order.confirm-select-table': 'Vui lòng chọn bàn để thanh toán!',
  'sale.fnb.btn-request-payment': 'Yêu cầu thanh toán',
  'sale.fnb.btn-request-notify': 'Yêu cầu thông báo',
  'sale.fnb.btn-confirm-payment': 'Xác nhận',
  'sale.fnb.order-payment-error': 'Thanh toán thất bại!',
  'sale.fnb.order-payment-success': 'Thanh toán thành công!',
  'sale.order.bayment.step-back': 'Quay lại',
  'sale.fnb.form-payment.btn.discount': 'Ưu đãi',
  'sale.fnb.form-payment.discount.title': 'Chọn mã ưu đãi',
  'sale.sell-casual-tab.title-discount': 'Ưu đãi',
  'sale.sell-casual-tab.title-btn-discount': 'Chọn mã',
  'sale.fnb.btn.apply': 'Áp dụng',
  'sale.fnb.you-are-not-discount': 'Hiện không có chương trình ưu đãi nào dành cho bạn',
  'sale-placeholder-search-order-take-away': 'Tìm kiếm khách hàng, sdt',
  'sale-title-take-away': 'Mang về',
  'sale.fnb.cancel-order-success': 'Hủy đơn hàng thành công!',
  'sale.noti.product.empty': 'Sản phẩm hết hàng',

  'sale.barcode-scanner-product-on': 'Máy quét sản phẩm đang bật',
  'sale.barcode-scanner-product-off': 'Máy quét sản phẩm đang tắt',
  'sale.barcode-scanner-user-on': 'Máy quét mã người dùng đang bật',
  'sale.barcode-scanner-user-off': 'Máy quét mã người dùng đang tắt',
  'sale.barcode-scanner-voucher-on': 'Máy quét mã giảm giá đang bật',
  'sale.barcode-scanner-voucher-off': 'Máy quét mã giảm giá đang tắt',

  'sale.barcode-scanner-on': 'Máy quét đang bật',
  'sale.barcode-scanner-off': 'Máy quét đang tắt',
  'sale.noti.have.voucher': 'Voucher đã chọn',
  'sale.allowed': 'Cho phép',
  'sale.not.allowed': 'Không cho phép',
  'sale.sell-casual-tab.title-discount-product': 'Chiết khấu sản phẩm',
  'sale.sell-casual-tab.title-discount-order': 'Chiết khấu đơn hàng',
  'sale.sell-casual-card-item-number': 'Số lượng',
  'sale.sell-casual-card-item-price': 'Giá bán',
  'sale.sell-casual-card-item-discount': 'CK',
  'sale.sell-casual-card-item-after-discount': 'Sau chiết khấu',
  'sale.sell-casual-card-item-total': 'Tổng',
  'sale.sell-casual-card-item-title-gift': 'Quà tặng',
  'sale.input.search.code': 'Nhập mã đơn hàng',
  'sale.not.approved.label': 'Chưa xác nhận',
};

export default vnLangSale;
