/* eslint-disable import/no-extraneous-dependencies */
import { handleActions } from 'redux-actions';
import * as Actions from './actions';

const initialState = {
  //
  isGetAllDishCancelReportRequest: false,
  isGetAllDishCancelReportSuccess: false,
  isGetAllDishCancelReportFailure: false,
  listDishCancelReportState: {},
  //
  //
  isGetAllDishCancelUserReportRequest: false,
  isGetAllDishCancelUserReportSuccess: false,
  isGetAllDishCancelUserReportFailure: false,
  listDishCancelUserReportState: {},
  //
  isGetRevenueReportRequest: false,
  isGetRevenueReportSuccess: false,
  isGetRevenueReportFailure: false,
  listRevenueReportState: {},

  // Get All Product instocks
  isGetAllProductsInstockRequest: false,
  isGetAllProductsInstockSuccess: false,
  isGetAllProductsInstockFailure: false,
  getAllProductsInstockState: {},
  errorMessages: [],
};

const reducer = handleActions({
  // #Report list Report
  [Actions.getAllDishCancelReportRequest]: (state) => ({
    ...state,
    isGetAllDishCancelReportRequest: true,
    isGetAllDishCancelReportSuccess: false,
    isGetAllDishCancellReportFailure: false,
  }),
  [Actions.getAllDishCancelReportSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllDishCancelReportRequest: false,
    isGetAllDishCancelReportSuccess: true,
    isGetAllDishCancelReportFailure: false,
    listDishCancelReportState: payload,
  }),
  [Actions.getAllDishCancelReportFailure]: (state, { payload }) => ({
    ...state,
    isGetAllDishCancelReportRequest: false,
    isGetAllDishCancelReportSuccess: false,
    isGetAllDishCancelReportFailure: true,
    errorMessages: payload,
  }),
  // #endReport

  // #Report user Report
  [Actions.getAllDishCancelUserReportRequest]: (state) => ({
    ...state,
    isGetAllDishCancelUserReportRequest: true,
    isGetAllDishCancelUserReportSuccess: false,
    isGetAllDishCancellUserReportFailure: false,
  }),
  [Actions.getAllDishCancelUserReportSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllDishCancelUserReportRequest: false,
    isGetAllDishCancelUserReportSuccess: true,
    isGetAllDishCancelUserReportFailure: false,
    listDishCancelUserReportState: payload,
  }),
  [Actions.getAllDishCancelUserReportFailure]: (state, { payload }) => ({
    ...state,
    isGetAllDishCancelUserReportRequest: false,
    isGetAllDishCancelUserReportSuccess: false,
    isGetAllDishCancelUserReportFailure: true,
    errorMessages: payload,
  }),
  // #endReport

  // #Report user Report
  [Actions.getRevenueReportRequest]: (state) => ({
    ...state,
    isGetRevenueReportRequest: true,
    isGetRevenueReportSuccess: false,
    isGetAllDishCancellUserReportFailure: false,
  }),
  [Actions.getRevenueReportSuccess]: (state, { payload }) => ({
    ...state,
    isGetRevenueReportRequest: false,
    isGetRevenueReportSuccess: true,
    isGetRevenueReportFailure: false,
    listRevenueReportState: payload,
  }),
  [Actions.getRevenueReportFailure]: (state, { payload }) => ({
    ...state,
    isGetRevenueReportRequest: false,
    isGetRevenueReportSuccess: false,
    isGetRevenueReportFailure: true,
    errorMessages: payload,
  }),
  // #endReport
  // #region : Get All Product Instock
  [Actions.getAllProductsInstocksRequest]: (state) => ({
    ...state,
    isGetAllProductsInstockRequest: true,
    isGetAllProductsInstockSuccess: false,
    isGetAllProductsInstockFailure: false,
  }),
  [Actions.getAllProductsInstocksSuccess]: (state, { payload }) => ({
    ...state,
    isGetAllProductsInstockRequest: false,
    isGetAllProductsInstockSuccess: true,
    isGetAllProductsInstockFailure: false,
    getAllProductsInstockState: payload,
  }),
  [Actions.getAllProductsInstocksFailure]: (state, { payload }) => ({
    ...state,
    isGetAllProductsInstockRequest: false,
    isGetAllProductsInstockSuccess: false,
    isGetAllProductsInstockFailure: true,
    errorMessages: payload,
  }),

  // #Report local
  [Actions.resetReportState]: () => initialState,
  // #endReport
}, initialState);

export default reducer;
