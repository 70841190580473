const vnLangReport = {
  'report.col.code': 'Mã HD',
  'report.col.name': 'Món ăn',
  'report.order.breadcrumb.title': 'Danh sách các món bị hủy',
  'report.staff.breadcrumb.title': 'Danh sách nhân viên hay hủy món',
  'report.col.date': 'Ngày',
  'report.col.total.name': 'Số lượng',
  'report.col.total-date.name': 'Tổng số lượng',
  'report.col.name.label': 'Nhân viên',
  'report.col.date.label': 'Thời gian',
  'report.col.time.label': 'Số lần hủy món',
  'report.col.total.label': 'Tổng số lần hủy món',

  'report.revenue.code': 'Mã đơn',
  'report.revenue.customer': 'Khách hàng',
  'report.revenue.money': 'Đơn giá',
  'report.revenue.discount': 'Giảm giá',
  'report.revenue.total': 'Thành tiền',
  'report.revenue.voucher': 'Mã khuyến mãi',
  'report.revenue.status': 'Trạng thái',
  'report.revenue.time': 'Thời gian',
  'report.reveneu.number': 'Số thứ tự',
  'report.reveneu.title': 'Tiêu đề',
  'report.reveneu.content': 'Nội dung',
  'report.reveneu.detail-request': 'Xem thêm chi tiết',
  'report.reveneu.detail-hidden': 'Ẩn',
  'report.discount.product.voucher': 'Voucher sản phẩm',

  'report.breadcrumb.export.slips.title': 'BC theo phiếu xuất',
  'report.breadcrumb.customer.title': 'BC theo khách hàng',
  'report.breadcrumb.product.title': 'BC theo hàng hóa',
  'report.breadcrumb.staff.title': 'BC theo nhân viên',
  'report.breadcrumb.inventory.title': 'Tồn kho tổng hợp',
  'report.breadcrumb.inventory.detail.title': 'Tồn kho chi tiết',

  'report.breadcrumb.export.slips.parent': '(hiện tại)',
  'report.export.slips.label.total': 'Tổng:',

  'report.export.slips.label.filter-1': 'Chọn filter 1',
  'report.export.slips.label.filter-2': 'Chọn filter 2',
  'report.export.slips.label.filter-3': 'Chọn filter 3',
  'report.export.slips.label.filter-4': 'Chọn filter 4',

  'report.export.slips.table.thead.stt': 'TT',
  'report.export.slips.table.thead.receipt': 'Chứng từ',

  'report.export.slips.table.thead.date.receipt': 'Ngày chứng từ',
  'report.export.slips.table.thead.code.product': 'Mã hàng hóa',

  'report.export.slips.table.thead.staff': 'Nhân viên',
  'report.export.slips.table.thead.customer': 'Khách hàng',
  'report.export.slips.table.thead.discount': 'CK',
  'report.export.slips.table.thead.money': 'Thành tiền',
  'report.export.slips.table.thead.total': 'Tổng tiền',
  'report.export.slips.table.thead.note': 'Ghi chú',

  'report.export.slips.table.thead.date': 'Ngày bán',
  'report.export.slips.table.thead.category.product': 'DM hàng hóa',
  'report.export.slips.table.thead.name.product': 'Tên hàng hóa',
  'report.export.slips.table.thead.unit.product': 'ĐVT',
  'report.export.slips.table.thead.number.product': 'S.lượng',
  'report.export.slips.table.thead.price.product': 'Đơn giá',
  'report.export.slips.table.thead.code.staff': 'Mã NV',
  'report.export.slips.table.thead.name.staff': 'Tên NV',
  'report.export.slips.table.thead.sale.money': 'Tiền bán',
  'report.export.slips.table.thead.cash': 'Tiền mặt',
  'report.export.slips.table.thead.debt': 'Công nợ',

  'report.export.slips.table.thead.name.product.inventory': 'Tên sản phẩm',
  'report.export.slips.table.thead.qc': 'QC',
  'report.export.slips.table.thead.inventory.start': 'Tồn đầu kỳ',
  'report.export.slips.table.thead.inventory.end': 'Tồn cuối kỳ',
  'report.export.slips.table.thead.inventory.import': 'Nhập',
  'report.export.slips.table.thead.inventory.export': 'Xuất',
  'report.export.slips.table.thead.inventory.waiting.export': 'Chờ xuất',
  'report.export.slips.table.thead.inventory.require.import': 'Yêu cầu nhập',

  'report.export.slips.table.thead.total.product': 'Tổng sản phẩm',

  'report.export.slips.table.tbody.date': 'Ngày xuất:',
  'report.export.slips.table.tbody.total': 'CỘNG THEO NHÓM HÀNG',
  'report.inventory.table.tbody.total': 'TỔNG NHÓM',

};

export default vnLangReport;
