const imports = {
  'import.main.info.title': 'Thông tin nhập hàng',
  'import.main.info.date': 'Ngày nhập hàng',
  'import.main.info.user': 'Người nhập hàng',
  'import.supplier': 'Nhà cung cấp',
  'import.warehouse': 'Nhập vào kho',
  'import.search.placeholder': 'Mã sản phẩm / Tên sản phẩm',

  'import.search.input.number.placeholder': 'Nhập số lượng',
  'import.search.input.price.placeholder': 'Nhập giá nhập',

  'import.search.order.placeholder': 'Mã đơn hàng',
  'import.main-product-import.title': 'Thông tin sản phẩm',
  'import.main-product-import.imei': 'Số seri/ imei',
  'import.main-product-import.number': 'Số lượng nhập',
  'import.main-product-import.price': 'Giá nhập (1 sp)',
  'import.main-product-import.supplier': 'Nhà cung cấp',
  'import.main-product-import.imei.required': 'Vui lòng nhập mã imei',
  'import.main-product-import.number.required': 'Vui lòng nhập số lượng',
  'import.main-product-import.price.required': 'Vui lòng nhập giá nhập',
  'import.main-product-import.date.required': 'Vui lòng  nhập hạn sử dụng',
  'import.main-product-import.name': 'Tên sản phẩm',
  'import.main-product-import.date': 'Hạn sử dụng',
  'import.main-product-import.date.notApply': 'Không áp dụng ngày hết hạn',
  'import.main.cancel': 'Hủy thay đổi',
  'import.main.cancel.confirm': 'Xác nhận hủy thay đổi',
  'import.main.cancel.confirmSure': 'Bạn chắc chắn hủy thay đổi này?',
  'import.main.create': 'Nhập kho',
  'import.main.create.success': 'Nhập kho thành công',
  'import.main.create.confirm': 'Xác nhận nhập kho',
  'import.main.create.confirmSure': 'Bạn chắc chắn nhập kho?',
  'import.main.error.overLength': 'Số lượng mã imei đã vượt quá số lượng sản phẩm bạn nhập',
  // 'import.main.error.enough': 'S',
  'import.main.complete': 'Hoàn thành',
  'import.main.cancel-change': 'Hủy thay đổi',
  'import.main.import-imei': 'Nhập imei',
  'import.main.import': 'Nhập',

  'import.main.table-stt': 'STT',
  'import.main.table-name-product': 'Tên sản phẩm',
  'import.main.table-number-product': 'Số lượng nhập',
  'import.main.table-price-product': 'Giá nhập',
  'import.main.table-expiry-product': 'Hạn sử dụng',

};

const list = {
  'import-list.name': 'Tên sản phẩm',
  'import-list.number': 'Số lượng ',
  'import-list.unit': 'Đơn vị',
  'import-list.price': 'Giá nhập',
  'import-list.imei': 'Mã imei/serial',
  'import-list.date': 'Ngày hết hạn',
  'import-list.style.col': 'Hình thức',
  'import-list.money-number.col': 'Số tiền & số lượng',
  'import-list.supplier.col': 'Nhà cung cấp',
  'import-list.user.col': 'Người nhập',
  'import-list.import': 'Nhập',
  'import-list.export': 'Xuất',
  'import-list.helmet': 'Danh sách nhập xuất',
  'import-list.breadcrumb.title': 'Danh sách nhập xuất',
  'import-list.breadcrumb.parent': 'Danh sách nhập xuất',
};

const exports = {
  'export.main.info.title': 'Thông tin xuất kho',
  'export.main.info.date': 'Ngày xuất kho',
  'export.main.info.user': 'Người xuất kho',
  'export.warehouse.title': 'Kho xuất',
  'export.supplier': 'Nhà cung cấp',
  'export.warehouse': 'Chọn kho xuất',
  'export.search.placeholder': 'Mã sản phẩm / Tên sản phẩm',
  'export.main-product-export.title': 'Thông tin sản phẩm',
  'export.main-product-export.imei': 'Số seri/ imei',
  'export.main-product-export.number': 'Số lượng xuất',
  'export.main-product-export.price': 'Giá xuất (1 sp)',
  'export.main-product-export.supplier': 'Nhà cung cấp',
  'export.main-product-export.imei.required': 'Vui lòng nhập mã imei',
  'export.main-product-export.number.required': 'Vui lòng nhập số lượng',
  'export.main-product-export.price.required': 'Vui lòng nhập giá nhập',
  'export.main-product-export.date.required': 'Vui lòng  nhập hạn sử dụng',
  'export-list.price': 'Giá xuất',

  'export.main-product-export.name': 'Tên sản phẩm',
  'export.main-product-export.date': 'Hạn sử dụng',
  'export.main.cancel': 'Hủy thay đổi',
  'export.main.cancel.confirm': 'Xác nhận hủy thay đổi',
  'export.main.cancel.confirmSure': 'Bạn chắc chắn hủy thay đổi này?',
  'export.main.create': 'Xuất kho',
  'export.main.create.success': 'Xuất kho thành công',
  'export.main.create.confirm': 'Xác nhận xuất kho',
  'export.main.create.confirmSure': 'Bạn chắc chắn xuất kho?',
  'export.main.error.overLength': 'Số lượng mã imei đã vượt quá số lượng sản phẩm bạn nhập',
  // 'export.main.error.enough': 'S',
  'export.main.complete': 'Hoàn thành',
  'export.main.cancel-change': 'Hủy thay đổi',
  'export.main.import-imei': 'Nhập imei',
  'export.main.export': 'Xuất',

  'export.main.table-stt': 'STT',
  'export.main.table-name-product': 'Tên sản phẩm',
  'export.main.table-number-product': 'Số lượng xuất',
  'export.main.table-price-product': 'Giá xuất',
  'export.main-product-warehouse.number': 'Số lượng trong kho',

};

const vnLangActionWarehouse = {
  ...imports,
  ...list,
  ...exports,
};

export default vnLangActionWarehouse;
